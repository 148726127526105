import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import CardItem from '../components/CardItem'

const BlogPage = props => {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  return (
    <Layout>
      <Hero
        title="Für Mitleser"
        subtitle="Lesen Sie, was wir zu sagen haben"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          title="Blog & Articles"
          subtitle="In unseren Blogeinträgen und Artikeln schreiben wir über aktuelle Themen rund um Compliance & Datenschutz, DevOps, sowie neuen und interessanten technologischen Konzepten."
        />
        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              {edges.map(({ node }) => (
                <div key={node.id} className="column is-one-third">
                  <CardItem
                    image={node.frontmatter.image}
                    title={node.frontmatter.title}
                    description={node.frontmatter.description}
                    tags={node.frontmatter.tags}
                    date={node.frontmatter.date}
                    slug={node.fields.slug}
                    author={
                      node.frontmatter.author || {
                        name: '-',
                        email: '',
                      }
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogPage

export const pageQuery = graphql`
  query BlogPageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, preview: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(height: 250, width: 400)
              }
            }
            title
            description
            date(formatString: "DD. MMMM, YYYY", locale: "de")
            tags
            author {
              name
              email
            }
          }
        }
      }
    }
    hero: file(relativePath: { eq: "blog.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
